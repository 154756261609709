import React from "react";
import ThemeLayout from "../../layout/themeLayout";
import { useEffect } from "react";

const FAQs = () => {
    return (
        <ThemeLayout>
            <section>
                <div className="gap">
                    <div className="container">
                        <div className="row justify-content-md-center">
                            <div className="col-12">
                                <h3 className="my-3">FAQs</h3>
                                <div className="py-3">
                                    <h5>1. What is Reverie?</h5>
                                    <p>Reverie is a platform that empowers individuals to showcase their unique talents, connect with a global audience, and unlock opportunities.</p>
                                    <h5>2. How can I get started on Reverie?</h5>
                                    <p>You can start by downloading the Reverie app from your app store and creating a free account.</p>
                                    <h5>3. What kind of talents can I showcase on Reverie?</h5>
                                    <p>Reverie welcomes all talents, from music and art to comedy and sports. It's a diverse platform for any skill you'd like to share.</p>
                                    <h5>4. Is Reverie available on both Android and iOS?</h5>
                                    <p>Yes, Reverie is available on both Android and iOS devices.</p>
                                    <h5>5. How do I upload my talent video on Reverie?</h5>
                                    <p>Simply create a profile, click "Upload," and follow the prompts to share your talent with the world.</p>
                                    <h5>6. Can I participate in talent challenges on Reverie?</h5>
                                    <p>Absolutely! You can challenge others or accept challenges to showcase your skills. It's a fun way to compete and connect.</p>
                                    <h5>7. How do I connect with talent scouts or companies on Reverie?</h5>
                                    <p>Use the "Organization" option in your profile settings to showcase your skills to talent scouts and companies.</p>
                                    <h5>8. Is Reverie safe and secure for users?</h5>
                                    <p>Yes, we prioritize your safety and privacy. We use encryption to protect your data and have strict community guidelines.</p>
                                    <h5>9. Can I interact with other users on Reverie?</h5>
                                    <p>Yes, you can follow, comment, and like content from other users. It's a social platform for sharing and connecting.</p>
                                    <h5>10. How can I report inappropriate content on Reverie?</h5>
                                    <p>You can report content or users that violate our community guidelines, and our team will review the reports.</p>
                                    <h5>11. Is Reverie a free app?</h5>
                                    <p>Reverie offers free access with premium subscription options for added features.</p>
                                    <h5>12. How can I contact Reverie support for assistance?</h5>
                                    <p>You can reach out to our support team within the app, or visit our website for more contact options.</p>
                                    <h5>13. Can I monetize my talent on Reverie?</h5>
                                    <p>Yes, you can monetize your content through challenges and by gaining followers, making it a great platform for aspiring professionals.</p>
                                    <h5>14. Is there a minimum age to use Reverie?</h5>
                                    <p>Users must be at least 13 years old to create an account on Reverie, there is no age limit beyond that. (You can change the age if you feel the need to)</p>
                                    <h5>15. How can I stay updated with Reverie's latest features and updates?</h5>
                                    <p>We regularly update the app. Stay tuned for notifications and check our website and social media for news.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </ThemeLayout>
    );
};

export default FAQs;
